<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Paket Layanan Dimiliki</b>
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/patient/add')"
        v-if="manipulateBtn == true"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" :source="1" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Table from '@/component/masters/payment-categories/TableUsedItem.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      manipulateBtn: false
    }
  },

  methods: {
    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")
        if (access_right[a] == "3002") {
          this.manipulateBtn = true
        }
      }
    },
  },

  mounted() {
    this.setActiveMenu()
    
    // Breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien", route: "" },
      { title: "Daftar Pasien", route: "/patient/list" },
      { title: "Paket Layanan Dimiliki" },
    ]);
  },
};
</script>
